import { HomeComponent } from './Home';
import './App.css';

function App() {
  return (
    <div className="App">
      <HomeComponent>
        
      </HomeComponent>
    </div>
  );
}

export default App;
